var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-card',{staticClass:"hallitem"},[_c('div',{staticClass:"title"},[_c('p',{staticStyle:{"font-size":"0.9rem","font-weight":"600"}},[_vm._v(_vm._s(_vm.taskData.title))]),_c('p',{staticStyle:{"font-size":"0.6rem","color":"#999999"}},[_vm._v(" 截止时间:"+_vm._s(_vm.taskData.endAt)+" ")])]),_c('div',{staticClass:"ask"},[_c('p',{staticStyle:{"font-size":"0.8rem","font-weight":"600"}},[_vm._v("任务要求")]),_c('div',{staticClass:"askbody"},_vm._l((_vm.askSetting),function(item,index){return _c('div',{key:index,staticClass:"askitem"},[_c('div',[_c('p',{staticStyle:{"color":"#5874ef"}},[_vm._v(" "+_vm._s(_vm.taskData.salaryListVos[0][item.fieID] && _vm.taskData.salaryListVos[0][item.fieID] !== null ? _vm.taskData.salaryListVos[0][item.fieID] : "-")+" ")]),_c('p',[_vm._v(_vm._s(item.name))])])])}),0)]),_c('div',{staticClass:"num"},[_c('span',{style:({ color: _vm.textColor(_vm.taskData.userStatus) })},[_vm._v(_vm._s(_vm.statusText(_vm.taskData.userStatus)))]),(
          _vm.taskData.userStatus === 'TOFINISH' ||
          _vm.taskData.userStatus === 'TOJUDGE'
        )?_c('el-button',{staticStyle:{"margin-left":"auto","color":"#fff","background-color":"#e6a23c"},attrs:{"round":"","size":"mini"},on:{"click":function($event){return _vm.closeTask(_vm.taskData)}}},[_vm._v(" 关闭任务 ")]):_vm._e(),_c('el-button',{staticStyle:{"color":"#fff"},style:({
          backgroundColor:
            _vm.taskData.userStatus === 'CLOSED' ? '#999999' : '#5874EF',
        }),attrs:{"round":"","size":"mini"},on:{"click":function($event){return _vm.receive(_vm.taskData)}}},[_vm._v(" 查看详情 ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }