<!--
 * @Author: dingguowei
 * @Date: 2023-06-13 10:13:17
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-21 14:22:29
-->
<!-- 用户端我的任务 -->
<template>
  <div class="myTask">
    <div class="head">
      <div
        class="headitem"
        v-for="(item, index) in headData"
        :key="index"
        @click="changeStatus(item.status)"
        :style="[
          {
            backgroundImage:
              status !== item.status
                ? `url(${item.bgcUrl})`
                : `url(${item.activebgcUrl})`,
          },
          { color: status === item.status ? '#fff' : '#000' },
          { marginRight: index === headData.length - 1 ? '0px' : '10px' },
        ]"
      >
        <span>{{ item.name }}</span>
        <span>{{ numberData[item.fieId] ? numberData[item.fieId] : 0 }}</span>
      </div>
    </div>
    <div class="body" v-if="itemData.length > 0">
      <user_task_item
        class="item"
        @closeTask="closeTask"
        v-for="(item, index) in itemData"
        :task-data="item"
        :key="index"
      ></user_task_item>
    </div>
    <el-empty style="flex: 1" v-else description="暂无数据"></el-empty>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      style="margin-left: AUTO; margin-top: 10px; display: block"
      :pager-count="7"
      small
      @current-change="userTask"
      :current-page.sync="pageNo"
      :page-size="pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
import { userTask, userGetNum } from "@/api/user";
import user_task_item from "@/components/user/user_task_item.vue";
export default {
  components: {
    user_task_item,
  },
  data() {
    return {
      headData: [
        {
          name: "全部任务",
          fieId: "totalNum",
          bgcUrl: require("@/assets/audit/1.png"),
          activebgcUrl: require("@/assets/audit/1active.png"),
          status: null,
        },
        {
          name: "已经关闭",
          fieId: "closedNum",
          bgcUrl: require("@/assets/audit/4.png"),
          activebgcUrl: require("@/assets/audit/4active.png"),
          status: "CLOSED",
        },
        {
          name: "进行中任务",
          fieId: "notSubmittedNum",
          bgcUrl: require("@/assets/audit/2.png"),
          activebgcUrl: require("@/assets/audit/2active.png"),
          status: "TOFINISH",
        },
        {
          name: "审核中任务",
          fieId: "submittedNum",
          bgcUrl: require("@/assets/audit/3.png"),
          activebgcUrl: require("@/assets/audit/3active.png"),
          status: "TOJUDGE",
        },
        {
          name: "已完成任务",
          fieId: "finishedNum",
          bgcUrl: require("@/assets/audit/4.png"),
          activebgcUrl: require("@/assets/audit/4active.png"),
          status: "FINISHED",
        },
        {
          name: "被拒绝任务",
          fieId: "refusedNum",
          bgcUrl: require("@/assets/audit/4.png"),
          activebgcUrl: require("@/assets/audit/4active.png"),
          status: "REFUSED",
        },
        {
          name: "被拉黑任务",
          fieId: "forbidNum",
          bgcUrl: require("@/assets/audit/4.png"),
          activebgcUrl: require("@/assets/audit/4active.png"),
          status: "FORBID",
        },
      ],
      numberData: [],
      itemData: [],
      status: null,
      pageNo: 1,
      pageSize: 9,
      total: 0,
    };
  },
  created() {},
  mounted() {
    this.userGetNum();
    this.userTask();
  },
  watch: {},
  computed: {},
  methods: {
    userGetNum() {
      userGetNum().then((res) => {
        this.numberData = res.data;
      });
    },
    userTask(val) {
      userTask({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        status: this.status,
      }).then((res) => {
        this.itemData = res.data.records;
        this.total = res.data.total;
        if (val) {
          this.$message({
            message: "当前任务已更新",
            type: "success",
          });
        }
      });
    },
    closeTask() {
      this.userGetNum();
      this.userTask();
    },
    changeStatus(val) {
      this.status = val;
      this.userTask(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.myTask {
  box-sizing: border-box;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  .head {
    display: flex;

    .headitem {
      cursor: pointer;
      flex: 1;
      height: 130px;
      margin-right: 20px;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
    }

    & span:first-child {
      padding: 0.6rem 1rem;
      font-size: 0.8rem;
      height: 2rem;
      line-height: 2rem;
    }

    & span:last-child {
      font-weight: 600;
      flex: 1;
      font-size: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .body {
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    .item {
      flex-basis: calc(33.33%);
      padding: 5px 5px;
      box-sizing: border-box;
    }
    .item--single {
      flex-basis: 100%;
    }
  }
}
</style>